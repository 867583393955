import {TextconstantService} from "../../tritec-catalog/node_modules/dc-services/textconstant.service"
import {FetchService} from "../../tritec-catalog/node_modules/dc-services/fetch.service";
import {MustacheService} from "../../tritec-catalog/node_modules/dc-services/mustache.service";

export function initMailchimp() {
    document.querySelectorAll('.js-mailchimpNewsletter form').forEach(
        formWrapper=> {
            formWrapper.addEventListener('submit', async event => {
                event.preventDefault();

                const recipientData = {
                    email: formWrapper.input_email.value,
                    firstName: formWrapper.input_first_name.value,
                    lastName: formWrapper.input_last_name.value,
                    city: formWrapper.input_city?.value,
                    postCode: formWrapper.input_post_code?.value,
                    salutation: formWrapper.input_salutation?.value,
                    company: formWrapper.input_company?.value
                }

                const returnValue = await FetchService.post('/em/subscribeToNewsletter', recipientData)

                    if(returnValue.success) {
                        const successMessageHeadline = await TextconstantService.getTextConstant("mailchimp_success_headline");
                        const successMessage = await TextconstantService.getTextConstant("mailchimp_success");

                        MustacheService.getRenderedTemplate('/layout/frontend/tritec-b2b/templates/shop', 'mailchimpSuccess.mustache', {successMessageHeadline, successMessage}).then(mailchimpSuccess =>{
                            formWrapper.append(...mailchimpSuccess);
                            formWrapper.querySelector('.mailchimpWrapper').style.display = 'flex';
                            initCloseButton(formWrapper);
                        });
                    } else {
                        const errorMessageHeadline = await TextconstantService.getTextConstant("mailchimp_error_headline");
                        const errorMessage = await TextconstantService.getTextConstant("mailchimp_error");

                        MustacheService.getRenderedTemplate('/layout/frontend/tritec-b2b/templates/shop', 'mailchimpError.mustache', {errorMessageHeadline, errorMessage}).then(mailchimpError =>{
                            formWrapper.append(...mailchimpError);
                            formWrapper.querySelector('.mailchimpWrapper').style.display = 'flex';
                            initCloseButton(formWrapper);
                        });
                    }

            })
        }
    )
}

function initCloseButton(wrapperElement) {
    wrapperElement.querySelectorAll('.closeIcon').forEach(
        closeButton => {
            closeButton.addEventListener('click', event => {
                closeButton.parentElement.style.display = 'none';
            })
        }
    )
}