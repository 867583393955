import Mustache from 'mustache'

export class MustacheService {
    static requestedTemplateMap: Map<string, Promise<Response>> = new Map<string, Promise<Response>>()

    private static async getTemplateFromServer(template: string): Promise<Response> {
        if (!this.requestedTemplateMap.has(template)) {
            const request = fetch(template)
            this.requestedTemplateMap.set(template, request)
        }
        return this.requestedTemplateMap.get(template)
    }

    private static async getTemplate(template: string): Promise<string> {
        const response = await this.getTemplateFromServer(template)
        if (response.ok) {
            return await response.text()
        } else {
            return ''
        }
    }

    static async getRenderedTemplate(
        templateSource: string,
        templateName: string,
        templateData: any = {},
        partials: { [key: string]: string } = {}
    ): Promise<HTMLCollection> {
        const tmpl = await this.getTemplate(`${templateSource}/${templateName}`)
        const partialsObj: { [key: string]: string } = {}
        for (const tn in partials) {
            partialsObj[tn] = await this.getTemplate(`${templateSource}/${partials[tn]}`)
        }
        const renderedTemplate = Mustache.render(tmpl, templateData, partialsObj)
        const domParser = new DOMParser()
        return domParser.parseFromString(renderedTemplate, 'text/html').body.children
    }
}