export function resetNavTogglerPositionMobile() {
    let navToggler = document.querySelector('#js-toggleNavigation');

    if (navToggler) {
        let headerMobileRowForm = document.querySelector('.headerMobileRow');
        let headerInformation = document.querySelector('.headerInformation');

        if (window.screen.width < 1024) {
            headerMobileRowForm.prepend(navToggler);
        } else {
            headerInformation.append(navToggler);
        }
    }
}

