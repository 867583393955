import { FetchService } from './fetch.service'

export class TextconstantService {
    private static textConstants: Map<string, string> = new Map()

    static async preloadTextConstants(prefix: string): Promise<void> {
        const constants = await FetchService.get<ITextConstant[]>(`/em/textConstants/${prefix}/`)
        if (constants.success) {
            constants.data.forEach(constant => {
                this.textConstants.set(constant.tag, constant.text)
            })
        }
    }

    static async getTextConstant(tag: string): Promise<string> {
        return this.textConstants.get(tag) || await this.getTextConstantFromServer(tag)
    }

    private static async getTextConstantFromServer(tag: string): Promise<string> {
        const newTextConstant = await FetchService.get<string>(`/em/textConstant/${tag}/`)
        if (newTextConstant.success) {
            this.textConstants.set(tag, newTextConstant.data)
            return newTextConstant.data
        } else {
            return ''
        }
    }

    static getAllTextConstants(): ITextConstants {
        const retVal: ITextConstants = {}
        this.textConstants.forEach((value, key) => {
            retVal[key] = value
        })
        return retVal
    }
}

interface ITextConstant {
    tag: string
    text: string
}

interface ITextConstants {
    [tag: string]: string
}