export function initDisableRegistrationForPrivate() {
    document.querySelectorAll('.iconbarIcon--becomeReseller').forEach((registrationButton) => {
        registrationButton.addEventListener('click', () => {
            disableRegistrationForPrivate();
        })
    })
}

function disableRegistrationForPrivate() {
    document.querySelectorAll('#register_modal .contactformular form').forEach(
        registrationForm => {
            let radioBoxes = registrationForm.querySelectorAll('.specialradiobox');
            let checkboxPrivate = radioBoxes[1].querySelector('input');
            let registrationFormNotice = document.createElement("div");
            registrationFormNotice.classList.add('registrationNotice');
            registrationFormNotice.innerHTML = tc_registration_notice;
            registrationForm.prepend(registrationFormNotice);
            let registrationFormNoticeHeight = registrationFormNotice.getBoundingClientRect().height;
            registrationForm.querySelector('#input_company').setAttribute('required', 'required');
            registrationForm.querySelector('#input_dealer-number').setAttribute('required', 'required');
            registrationForm.querySelector('#input_document').setAttribute('required', 'required');

            registrationForm.addEventListener('change', evt => {
                if(!checkboxPrivate.checked) {
                    registrationForm.querySelector('#input_company').disabled = false;
                    registrationForm.querySelector('#input_dealer-number').disabled = false;
                    registrationForm.querySelector('#input_document').disabled = false;
                    registrationForm.querySelector('#input_company').setAttribute('required', 'required');
                    registrationForm.querySelector('#input_dealer-number').setAttribute('required', 'required');
                    registrationForm.querySelector('#input_document').setAttribute('required', 'required');
                    registrationFormNotice.style.display = 'none';
                    registrationFormNotice.style.opacity = '0';
                } else {
                    registrationForm.querySelector('#input_company').disabled = true;
                    registrationForm.querySelector('#input_dealer-number').disabled = true;
                    registrationForm.querySelector('#input_document').disabled = true;
                    registrationForm.querySelector('#input_company').removeAttribute('required');
                    registrationForm.querySelector('#input_dealer-number').removeAttribute('required');
                    registrationForm.querySelector('#input_document').removeAttribute('required');
                    registrationFormNotice.style.display = 'flex';
                    registrationFormNotice.style.opacity = '1';
                }
            });
        }
    )
}